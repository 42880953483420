// Generated by Framer (ad22381)

import { addFonts, cx, CycleVariantState, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["bbNNe7BkX"];

const variantClassNames = {bbNNe7BkX: "framer-v-19uy0zz"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "bbNNe7BkX", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "bbNNe7BkX", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-TeHI6", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-19uy0zz", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"bbNNe7BkX"} ref={ref} style={{backgroundColor: "rgb(255, 255, 255)", ...style}} transition={transition}/>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-TeHI6 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-TeHI6 .framer-1rrv6wc { display: block; }", ".framer-TeHI6 .framer-19uy0zz { height: 112px; overflow: visible; position: relative; width: 1440px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 112
 * @framerIntrinsicWidth 1440
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 */
const FramerLxL_YzFL6: React.ComponentType<Props> = withCSS(Component, css, "framer-TeHI6") as typeof Component;
export default FramerLxL_YzFL6;

FramerLxL_YzFL6.displayName = "Navigation Bar / Type #2";

FramerLxL_YzFL6.defaultProps = {height: 112, width: 1440};

addFonts(FramerLxL_YzFL6, [])